import { AskADemo } from 'libs/brand-system/src/templates/AskADemo';
import { plainTextToStructuredText } from 'libs/brand-system/src/utils/structuredText';
import { Section } from 'libs/growth-platform-brand-system-v2/src/components/Section';
import { container } from 'libs/growth-platform-brand-system-v2/src/styles/themes';
import { textTitle1 } from 'libs/growth-platform-brand-system-v2/src/styles/typography';
import { Content } from 'libs/growth-platform-brand-system-v2/src/templates/Content';
import { HeroBlog } from 'libs/growth-platform-brand-system-v2/src/templates/HeroBlog';
import { classNames } from 'libs/growth-platform-brand-system-v2/src/utils/style';
import { useScreenSize } from 'libs/growth-platform-brand-system-v2/src/utils/utils';
import { graphql } from 'gatsby';
import { morphism } from 'morphism';
import React from 'react';
import Layout from '../../layout';
import { HeadWrapper } from '../../layout/HeadWrapper';
import Subheader from '../../layout/subheader';
import { schemaContent } from '../../morphismSchema/templates/schemaContent';
import { schemaHeroBlog } from '../../morphismSchema/templates/schemaHeroBlog';
import { getButtons } from '../../utils/cleanProps';
import { getBlogListCtaButtons } from './logic';

export const Head = ({ data, pageContext, location }: any) => (
  <HeadWrapper
    data={data}
    pageContext={pageContext}
    location={location}
    templateName="BlogHomepage"
    dataName={'datoCmsBlogHome'}
    largestContentfullPaintImg={null}
  />
);

export const query = graphql`
  query DatoCmsBlogHome($locale: String) {
    datoCmsBlogHome(locale: $locale) {
      ...BlogHomePage
    }
    allDatoCmsCategoryPage(
      locale: $locale
      filter: { locales: { eq: $locale } }
      sort: { position: ASC }
    ) {
      nodes {
        id
        slug
        hero {
          title
          subtitle
          image {
            url
            alt
            gatsbyImageData
            width
            height
          }
        }
        trackingPageName {
          title
        }
        highlights {
          slug
          hero {
            title
          }
          trackingPageName {
            title
          }
        }
      }
    }
  }
`;

const BlogHomepage = ({ data, pageContext }: any) => {
  const screenSize = useScreenSize();

  const page = data.datoCmsBlogHome;
  const hero = page.hero?.[0];
  const titleBlock = page.title?.[0];
  const categories = data.allDatoCmsCategoryPage?.nodes ?? [];
  const askADemo = page.askADemo?.[0];

  return (
    <Layout
      pageContext={pageContext}
      data={page}
      customHeader={page.header}
      templateName="BlogHomepage"
    >
      <div className={classNames(container)}>
        <Subheader
          doc={page}
          hero={hero}
          slugBase={page.slug}
          pageContext={pageContext}
        />
      </div>

      <HeroBlog
        {...(morphism(schemaHeroBlog, {
          ...hero,
          locale: pageContext.locale,
        }) as any)}
        isCentered={true}
        locale={pageContext?.locale}
        className="mx-24 md:mx-auto"
        titleClassName={
          'font-serif sm:text-48 sm:leading-52 md:text-56 md:leading-60 xl:text-64 xl:leading-68 text-32 leading-[36px]'
        }
      />
      <Section>
        <div className={'flex flex-col items-center container pb-0'}>
          {titleBlock && (
            <p
              className={classNames(
                textTitle1,
                'font-medium text-center md:w-1/2',
              )}
            >
              {titleBlock.title}
            </p>
          )}
        </div>
        <div className="pt-8 md:pt-0">
          {categories?.map((category: any, i: number) => {
            const categoryHero = category.hero?.[0];

            return (
              <Content
                {...morphism(schemaContent, {
                  ...categoryHero,
                  title: plainTextToStructuredText(categoryHero.title),
                  subtitle: plainTextToStructuredText(categoryHero.subtitle),
                  image:
                    screenSize === 'xs' || screenSize === 'sm'
                      ? null
                      : categoryHero.image,
                })}
                disabledAnimation={i < 1}
                className="py-8 md:py-32"
                buttonDirection="vertical"
                key={`categories-${i}`}
              >
                {getBlogListCtaButtons({
                  category,
                  locale: pageContext.locale,
                  index: i,
                  section: 'categoriesLink',
                })}
              </Content>
            );
          })}
        </div>
        <div>
          {askADemo && (
            <section className="pt-32 md:pt-72" id="subscription-form">
              {askADemo.askADemoLink.reduce(
                (acc: any[], link: any, keyLink: number) => {
                  if (
                    link.__typename === 'DatoCmsTemplateAskADemo' &&
                    link.locale === page.locale
                  )
                    acc.push(
                      <AskADemo
                        {...link}
                        ctas={getButtons(
                          link,
                          pageContext.locale,
                          `${keyLink}_DatoCmsTemplateAskADemo`,
                        )}
                        key={`askADemoLink-${keyLink}`}
                      />,
                    );

                  return acc;
                },
                [],
              )}
            </section>
          )}
        </div>
      </Section>
    </Layout>
  );
};

export default BlogHomepage;
